<template>
  <div>
    <MyHeader />
    <main id="content">
      <!-- <div class="filter-destinations">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-11 col-xl-10">
              <form action=""
                    class="searchForm">
                <div class="col-12 mb-3 mb-lg-0 col-md-5 col-lg-4">
                  <div class="select">
                    <i class="icon-location" />
                    <select class="destinySelect form-control">
                      <option disabled
                              selected>
                        Search Be Like Destinations
                      </option>
                      <option>Casa de Campo, Dominican Republic</option>
                      <option>Punta Cana, Dominican Republic</option>
                      <option>Cabrera, Dominican Republic</option>
                      <option>Las Terrenas, Dominican Republic</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 mb-3 mb-lg-0 col-md-auto border-left border-right">
                  <label for="calenBook"
                        class="calenBook">
                    <i class="icon-calendar" />
                    <div id="startdate"
                        class="mr-1">Check In</div>
                    <i class="icon-arrow-right" />
                    <div id="enddate">Check Out</div>
                  </label>
                  <input id="calenBook"
                        type="text"
                        class="datepick form-control">
                </div>
                <div class="col-12 mb-3 mb-lg-0 col-md-2 col-lg-auto">
                  <div class="select">
                    <i class="icon-users" />
                    <select class="guestSelect form-control">
                      <option disabled
                              selected>
                        Guests
                      </option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-lg">
                  <button class="btn  btn-primary">
                    SEARCH
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> -->
      <div class="background-introduction align-items-end"
           :style="{ backgroundImage: 'url(' + featured_experience.image.path + ')' }"
           v-if="featured_experience && featured_experience.image && featured_experience.image.path">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-8 text-center">
              <h4 class="m-1">
                {{ featured_experience.name }}
              </h4>
              <p>Stay with all the comforts of home, verified.</p>
              <router-link class="btn btn-white"
                           :to="{ name: 'experience-detail', query: { destination_label: featured_experience.name, destination_type: 'country', destination_id: featured_experience.id, category_id: $route.query.category_id }}">
                Show {{ featured_experience.name }} experiences
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5 destinations"
           v-if="experiences && experiences.length > 0">
        <h4><span>HOME <span class="black">BE</span> LIKE</span> Experiences by Destination</h4>
        <ul class="mt-3 destinations-list">
          <li v-for="(experience, index) in experiences"
              :key="index">
            <router-link :to="{ name: 'experience-detail', query: { destination_label: experience.name, destination_type: 'country', destination_id: experience.id, category_id: $route.query.category_id }}"
                         :style="{ backgroundImage: 'url(' + experience.image.path + ')' }">
              <div class="destinations-info">
                <h4>{{ experience.name }}</h4>
                <hr>
                <p><small>{{ experience.verified_experiences }} + Verified Experiences</small></p>
              </div>
            </router-link>
          </li>
        </ul>
        <hr>
      </div>
      <!-- <div class="container mt-5 recomendations">
        <div class="row">
          <div class="col">
            <h2 class="subtitle mt-5">
              NEW LISTINGS
            </h2>
            <p>Basados en las calificaciones y comentarios de nuestros huéspedes hemos creado un selecto menú cada uno de los destinos y sus experiencias para que pueda dar un vistazo a cada uno y seleccione el que más se acomode a sus necesidades. </p>
          </div>
        </div>
        <PropertyList :config="{ limit: 4, sort_field: 'created_at', sort_order: 'DESC'}" />
      </div> -->
    </main>
    <MyFooter />
  </div>
</template>

<script>
// import PropertyList from '@/components/PropertyList.vue';

export default {
  name: 'Experiences',
  data() {
    return {
      experiences: [],
      featured_experience: {},
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Experiences',
    // all titles will be injected into this template
    // titleTemplate: '%s | Exclusive experience specialists',
    meta: [
      { name: 'description', content: 'Discover great experiences in all our destinations.' },
      // Schema.org markup for Google+
      { itemprop: 'name', content: 'CapCana Rentals | Exclusive experience specialists' },
      { itemprop: 'description', content: 'Discover great experiences in all our destinations.' },
      { itemprop: 'image', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      // Twitter Card data
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'CapCana Rentals | Exclusive experience specialists' },
      { name: 'twitter:description', content: 'Discover great experiences in all our destinations.' },
      // Twitter summary card with large image must be at least 280x150px
      { name: 'twitter:image:src', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      // Open Graph data
      { property: 'og:title', content: 'CapCana Rentals | Exclusive experience specialists' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://homebelike.com/experiences' },
      { property: 'og:image', content: 'https://homebelike.com/img/img-homebelike.jpg' },
      { property: 'og:description', content: 'Discover great experiences in all our destinations.' },
    ]
  },
  components: {
    // PropertyList
  },
  created() {
    const categoryID = this.$route.query.category_id ? this.$route.query.category_id : '';
    this.$axios.get(`/v1/experiences/countries?category_id=${categoryID}`).then((response) => {
      this.experiences = response.data.data.countries;
      this.featured_experience = response.data.data.featured_country;
      // this.$store.commit('setIsLoading', false);
    });
  },
};
</script>
